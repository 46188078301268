import React from "react"
import { Button } from "../../Global/Gui/Buttons"
import { Image } from "../../Global/Gui/Image"

const Hero = ({ data }) => {
  const {
    header,
    description,
    primaryUrl,
    primaryCtaText,
    secondaryUrl,
    secondaryCtaText,
    image,
  } = data
  console.log(image)
  return (
    <div class="xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32 z-10 relative">
      <div className="bg-annmade-1.0-blue absolute -right-full -left-full inset-y-0 z-0" />
      <div class="text-center relative">
        <h2 class="text-left sm:text-left md:text-center text-3xl md:text-4xl font-extrabold mb-4 text-white w-13-rem xs:w-13-rem md:w-full">
          {header}
        </h2>
        <h3
          class={`text-left sm:text-left md:text-center text-lg md:text-xl md:leading-relaxed font-medium text-white md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto`}
        >
          {description.description}
        </h3>
      </div>
      <div class="relative flex flex-col sm:flex-row sm:items-center sm:justify-center space-y-2 sm:space-y-0 sm:space-x-2 pt-10 pb-16">
        {primaryUrl ? (
          <Button theme={"LAUNCH-DARK"} href={primaryUrl}>
            {primaryCtaText ? (
              <span>{primaryCtaText}</span>
            ) : (
              <span>"Learn More"</span>
            )}
            <svg
              class="hi-solid hi-scissors inline-block w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5.5 2a3.5 3.5 0 101.665 6.58L8.585 10l-1.42 1.42a3.5 3.5 0 101.414 1.414l8.128-8.127a1 1 0 00-1.414-1.414L10 8.586l-1.42-1.42A3.5 3.5 0 005.5 2zM4 5.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 9a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
                clip-rule="evenodd"
              />
              <path d="M12.828 11.414a1 1 0 00-1.414 1.414l3.879 3.88a1 1 0 001.414-1.415l-3.879-3.879z" />
            </svg>
          </Button>
        ) : null}

        {secondaryUrl ? (
          <Button theme={`LAUNCH-LIGHT`} href={secondaryUrl}>
            {secondaryCtaText ? (
              <span>{secondaryCtaText}</span>
            ) : (
              <span>"Learn More"</span>
            )}
            <svg
              class="hi-solid hi-arrow-narrow-right inline-block w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>{" "}
          </Button>
        ) : null}
      </div>
      <div class="relative mx-5 lg:m-0-auto max-w-36-rem flex flex-col items-center justify-center">
        <div class="absolute inset-0 rounded-xl bg-white bg-opacity-20 -m-4 transform rotate-2"></div>
        <div class="absolute inset-0 rounded-xl bg-white bg-opacity-25 -m-4 transform -rotate-2"></div>
        {image && (
          <Image image={image.localFile.childImageSharp.gatsbyImageData} />
        )}
      </div>
    </div>
  )
}

export default Hero

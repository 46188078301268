import React from "react"
import { Render } from "../../../utilities/rtf"

const OurStory = ({ data }) => {
  const { title, body } = data

  return (
    <div class="relative" id={'our-story'}>
      <div className="bg-gray-100 absolute -right-full -left-full inset-y-0 z-0" />
      <div className="relative">
        <div class="space-y-16 xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
          <div class="text-center">
            <h2 class="text-3xl md:text-4xl font-extrabold mb-4 text-gray-600">
              {title}
            </h2>
            {body ? (
              <h3 class="text-lg md:text-xl md:leading-relaxed font-medium text-gray-500 lg:w-2/3 xl:w-10/12 mx-auto">
                {Render(body.body)}
              </h3>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurStory

import React from "react"
import { Carousel } from "../../Shared"
import { Image } from "../../Global/Gui/Image"

const CTA = ({ data }) => {
  const { header, description, gallery } = data
  return (
    <div class="bg-white">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-12 container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
        <div class="flex items-center text-center lg:text-left">
          <div class="space-y-10">
            <div>
              <div class="text-sm uppercase font-bold tracking-wider mb-1 text-blue-600">
                The Why
              </div>
              <h2 class="text-3xl md:text-4xl font-extrabold mb-4">{header}</h2>
              <h3 class="text-lg md:text-xl md:leading-relaxed font-medium text-gray-600">
                {description.description}
              </h3>
            </div>
          </div>
        </div>
        <div className={`w-full max-w-36-rem m-0-auto`}>
          {gallery && gallery.length === 1 && (
            <Image
              image={gallery[0].localFile.childImageSharp.gatsbyImageData}
            />
          )}
          {gallery && gallery.length > 1 && <Carousel images={gallery} />}
        </div>{" "}
      </div>
    </div>
  )
}

export default CTA

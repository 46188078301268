import { graphql } from "gatsby"
import React from "react"
import { SiteMetadata } from "../components/Shared"
import { PageLayout } from "../components/Global/Layouts"
import { Hero, OurStory, CTA } from "../components/PageSpecific/about"

const AboutPage = ({ data }) => {
  const { hero, cta, story } = data
  return (
    <PageLayout>
      <SiteMetadata title="About" description="About AnnMade" />
      <Hero data={hero} />
      <CTA data={cta}/>
      <OurStory data={story} />
    </PageLayout>
  )
}

export default AboutPage

export const query = graphql`
  {
    hero: contentfulPageHero(uuid: { eq: "about-hero" }) {
      header
      description {
        description
      }
      primaryCtaText
      primaryUrl
      secondaryCtaText
      secondaryUrl
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    cta: contentfulCtaFeaturedImage(
      uuid: { eq: "about-our-mission-and-values" }
    ) {
      header
      description {
        description
      }
      gallery {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(width: 960, quality: 85, layout: CONSTRAINED)
          }
        }
      }
    }
    story: contentfulBasicText(uuid: { eq: "homepage-our-story" }) {
      body {
        body
      }
      title
    }
  }
`
